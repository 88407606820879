/*
This is your Nav component. It contain a responsive navbar 
*/

import { LogOut, Menu } from "react-feather";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
// import Logo from 'public/logo.svg';
// import Logo from './UI/Logo'
import { User } from "@supabase/gotrue-js";

type NavProperties = {
  user: User | null | undefined;
  signOut: () => void;
};

const ThemeToggle = dynamic(() => import("components/UI/ThemeToggle"), {
  ssr: false,
});

const Nav = ({ user, signOut }: NavProperties): JSX.Element => {
  // Modify you menu directly here
  const NavMenu = (
    <>
      {user && (
        <>
          <Link href="/summaries">Summaries</Link>
          <Link href="/topics">Topics</Link>
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfUArKqaSUwSPgN_bOLap0vt57CYHS39fHxSVOPP9Ep3LJQTg/viewform?usp=sharing"
            rel="noopener noreferrer">
            Bug Report / Feedback
          </a>
          {/* <Link href="/members-only">
            <a className="nav-btn">Premium user </a>
          </Link> */}
        </>
      )}

      {/* <Link href="/blog">
        <a id="blog" className="nav-btn">
          Blog
        </a>
      </Link>

      <Link href="/pricing">
        <a id="pricing" className="nav-btn">
          Pricing
        </a>
      </Link>

      <Link href="/contact">
        <a id="contact" className="nav-btn">
          Contact Us
        </a>
      </Link> */}

      {user ? (
        <button
          id="logOutBtn"
          className="btn-xs btn text-xs"
          onClick={() => {
            signOut();
            window.location.href = "/?logout=true";
          }}>
          <LogOut size={12} className="mr-2" />
          Logout
        </button>
      ) : (
        <>
          <Link href="/login" legacyBehavior passHref={true}>
            <a id="login" className="nav-btn">
              Login
            </a>
          </Link>
          {/* <Link href="/signup" legacyBehavior passHref={true}>
            <a
              id="signup"
              className="btn-primary btn-sm btn font-body font-normal normal-case">
              Sign Up
            </a>
          </Link> */}
        </>
      )}
    </>
  );

  return (
    <nav className="navbar z-50 mb-6 w-full">
      <Link className="ml-4" href={user ? "/summaries" : "/"}>
        <Image
          src="/logo_3.png"
          width={260}
          height={0}
          style={{ width: "260px" }}
          alt="Main Idea logo"
        />
      </Link>

      <div className="ml-auto hidden flex-col text-center font-body text-sm lg:flex lg:flex-row lg:space-x-10">
        {NavMenu}
        <ThemeToggle />
      </div>
      <div className="ml-auto lg:hidden">
        <div className="dropdown-end dropdown" data-cy="dropdown">
          <div tabIndex={0} role="button" className="m-1 cursor-pointer">
            <Menu />
          </div>
          <div
            tabIndex={0}
            className="dropdown-content menu z-[1] mx-2 mt-3 w-24 space-y-3 border-2 border-solid border-sky-400 bg-base-100 text-center">
            {NavMenu}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
