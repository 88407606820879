import Link from "next/link";
import dynamic from "next/dynamic";

const Footer = (): JSX.Element => {
  const ThemeToggle = dynamic(() => import("components/UI/ThemeToggle"), {
    ssr: false,
  });
  return (
    <footer className="flex w-full">
      <nav className="mr-auto">
        <div className="flex w-full flex-col justify-evenly sm:flex-row sm:space-x-10">
          {/* <div className="">© {process.env.NEXT_PUBLIC_TITLE}</div> */}
          <Link href="/privacy">Privacy Policy</Link>
          {/* <Link href="/terms">Terms of service</Link> */}
        </div>
      </nav>
      <div className="my-auto mr-5 lg:hidden">
        <ThemeToggle />
      </div>
    </footer>
  );
};

export default Footer;
