import CardsLanding from "components/CardsLanding";
import Image from "next/image";
import MailingList from "./MailingList";
import landTop from "public/landing/land-top.svg";
import start from "public/landing/start.svg";
import supabaseImage from "public/landing/supabase.svg";
import heroImage from "public/landing/hero_banner5.svg";

const Landing = (): JSX.Element => (
  <div className="mb-20 w-full text-base-content">
    <div className="relative">
      <div
        className="relative z-10 flex flex-col items-center"
        style={{ marginTop: 30 }}>
        {/* <h1 className="font-custom">Supercharge Your Research</h1> */}
        <div className="row flex">
          <h1 className="animate-text bg-gradient-to-r from-sky-500 via-purple-500 to-blue-500 bg-clip-text font-black leading-tight tracking-wide text-transparent opacity-90 md:text-4xl lg:text-6xl lg:leading-tight">
            Supercharge your research
          </h1>
          <h1 className="ml-4 pt-1 md:text-4xl lg:text-6xl lg:leading-tight">
            📚
          </h1>
        </div>

        <p className="font-custom text-lg tracking-wide opacity-80">
          Understand concepts and draw your own conclusions using our AI-powered
          research tool
        </p>
      </div>
      <div
        className="z-10  flex flex-col items-center"
        style={{ position: "inherit", marginTop: 250 }}>
        <span>
          Currently in a closed beta. Follow{" "}
          <a
            className="text-blue-500 hover:underline"
            href="https://twitter.com/mainideaapp"
            target="_blank"
            rel="noreferrer">
            @mainideaapp
          </a>{" "}
          for updates or to participate.
        </span>
      </div>
    </div>
    {/* <Image
      // priority
      src={heroImage as string}
      layout="fill"
      objectFit="cover"
      objectPosition="center"
      alt="hero image"
      // className="opacity-80"
      className="-z-2 pt-30 m-auto max-w-6xl opacity-30"
    /> */}

    {/* <div className="m-auto flex max-w-6xl flex-wrap justify-around">
      <div className="grid h-64 p-5">
        <div className="col-start-1 row-start-1 self-center"> */}
    <div className="grid grid-cols-1 place-content-center gap-4">
      <div></div>
      <div></div>
      <div></div>
    </div>
    {/* </div>
      </div>
    </div> */}
  </div>
);

export default Landing;
