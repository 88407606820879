/*
Don't forget to modify the Head component with your website informations
You can also update the content on the Landing.js component
*/
import Script from "next/script";
import Head from "next/head";
import Landing from "components/Landing";
import Layout from "components/Layout";
// import { useRouter } from 'next/router';
// import { useEffect, useState } from 'react';
// import { useAuth } from 'utils/AuthContext';

const Home = (): JSX.Element => {
  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-3GG2VJRV8F" />
      <Script id="google-analytics">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', 'G-3GG2VJRV8F');
          `}
      </Script>
      <Head>
        <title>{`${
          process.env.NEXT_PUBLIC_TITLE ? process.env.NEXT_PUBLIC_TITLE : ""
        }`}</title>
        {/* <meta
          name="description"
          content="Cliff notes for your browser. Main Idea intelligently summarizes short and long-form content in seconds."
        /> */}

        <meta property="og:url" content="https://www.main-idea.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Welcome to ${
            process.env.NEXT_PUBLIC_TITLE ? process.env.NEXT_PUBLIC_TITLE : ""
          } 👋`}
        />
        {/* <meta
          property="og:description"
          content="Cliff notes for your browser. Main Idea intelligently summarizes short and long-form content in seconds."
        /> */}
        {/* <meta property="og:image" content="https://www.main-idea.com/ogimage.png" /> */}

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="main-idea.com" />
        {/* <meta
        property="twitter:url"
        content="https://www.main-idea.com/ogimage.png"
      /> */}
        <meta
          name="twitter:title"
          content={`Welcome to ${
            process.env.NEXT_PUBLIC_TITLE ? process.env.NEXT_PUBLIC_TITLE : ""
          } 👋`}
        />
        {/* <meta
          name="twitter:description"
          content="Main Idea intelligently summarizes short and long-form content in seconds."
        /> */}
        {/* <meta
        name="twitter:image"
        content="https://www.main-idea.com/ogimage.png"
      /> */}
        <meta charSet="UTF-8" />
      </Head>
      <Layout>
        <Landing />
      </Layout>
    </>
  );
};
export default Home;
